.container {
  width: 90%;
  margin: auto;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav {
  color: #f8f8f8;
  font-family: "Nunito", sans-serif;
  position: relative;
}

.title {
  font-weight: 700;
  color: white;
  font-size: 2rem;
}

.links {
  margin-left: 1rem;
  color: white;
}

.cta-1 {
  margin-right: 1.2rem;
  color: #f8f8f8;
  background-color: #4f6ef7;
  padding: 0.6rem 1.4rem;
  border-radius: 0.2rem;
  font-size: 0.8rem;
  font-weight: bold;
  transition: all 300ms ease-in;
}

.cta-2 {
  color: #4f6ef7;
  padding: 0.6rem 1.4rem;
  border: 1px solid #4f6ef7;
  border-radius: 0.2rem;
  font-size: 0.8rem;
  font-weight: bold;
  transition: all 300ms ease-in;
}

.cta-1:hover {
  color: #4f6ef7;
  background-color: #f8f8f8;
}

.cta-2:hover {
  color: #4f6ef7;
  background-color: #f8f8f8;
  border: none;
}

.bars {
  display: none;
  align-self: center;
}

.sidenav {
  width: 100%;
  background-color: rgb(95, 192, 38);
  z-index: 4;
  position: absolute;
  left: -999%;
  transition: 560ms ease;
}

.sidenav.active {
  left: 0;
  transition: 560ms ease;
}

.img-res {
  width: 20%;
  height: 50px;
}

@media screen and (max-width: 780px) {
  .cta-1,
  .cta-2,
  .links {
    display: none;
  }

  .bars {
    display: block;
  }
}
