.input {
  border: none;
  border-radius: 50px;
  padding: 0.4rem;
  margin-right: 0.4rem;
  outline: none;
}

.footer {
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}